<!--
 * @Author:DY
 * @Date: 2020-07-08 20:10:25
 * @LastEditTime: 2020-08-15 16:38:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JT_Web_dev\src\views\contractControl\control.vue
-->
<template>
    <div
        class="control"
        v-loading="loading"
        element-loading-text="拼命加载中"
    >
        <div class="controlContant">
            <table>
                <thead>
                    <tr>
                        <th colspan="4">
                            超生产任务方量发货管控处置表
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>管控编号：</th>
                        <td>{{ controlData.control_instance_code }}</td>
                        <th>任务单编号：</th>
                        <td>{{ controlData.task_number }}</td>
                    </tr>
                    <tr>
                        <th>任务方量：</th>
                        <td>{{ controlData.quantity }}</td>
                        <th>已发货方量：</th>
                        <td>{{ controlData.task_delivery_quantity }}</td>
                    </tr>
                    <tr>
                        <th>工程名称：</th>
                        <td>{{ controlData.project_name }}</td>
                        <th>客户名称：</th>
                        <td>{{ controlData.customer_name }}</td>
                    </tr>
                    <tr>
                        <th>管控时间：</th>
                        <td>{{ controlData.control_start_time }}</td>
                        <th>新增发货方量：</th>
                        <td>{{ controlData.new_delivery_quantity }}</td>
                    </tr>
                    <tr>
                        <th>管理处置意见：</th>
                        <td colspan="3" class="remarks">
                            <div class="opinion">
                                <textarea
                                    rows="2"
                                    @change="modify()"
                                    v-model="controlData.disposal_description"
                                    placeholder="此处为备注内容"
                                ></textarea>
                                <div class="info">
                                    <span class="name" v-if="controlData.disposal_user_name">
                                        <i class="iconfont iconkehu1"></i>
                                        {{ controlData.disposal_user_name }}
                                    </span>
                                    <span class="time" v-if="controlData.disposal_time">
                                        <i class="iconfont iconshijian"></i>
                                        {{ controlData.disposal_time }}
                                    </span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>备注信息：</th>
                        <td colspan="3" class="remarks">
                            <textarea
                                rows="2"
                                @change="modify()"
                                v-model="controlData.remarks"
                                placeholder="此处为备注内容"
                            ></textarea>
                        </td>
                    </tr>
                    <tr>
                        <th>审批结果查看</th>
                        <td colspan="3" class="link">
                            <p
                                v-if="controlData.transfinite_business_instance_id"
                                @click="see(controlData.transfinite_business_instance_id)"
                            >
                                查看超限审批结果
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <footer>
                <button @click="Close">
                    关闭
                </button>
                <button @click="preservation">
                    保存
                </button>
            </footer>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            // 超发管控数据
            controlData: {},
            // 是否修改过备注或者意见
            isModify: false,
            // 加载状态
            loading: false,
        };
    },
    computed: {},
    watch: {},
    created() {
        this.loading = true;
        // 获取超发管控数据
        this.getControlData();
    },
    mounted() { },
    destroyed() { },
    methods: {
        Close() {
            this.$parent.hide();
        },
        // 获取超发管控数据
        getControlData() {
            this.$axios
                .get(`/interfaceApi/sale/control/exceed_task/dispose/${this.extr.task_number}`)
                .then(res => {
                    this.controlData = res;
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 意见或者备注修改触发
        modify() {
            this.isModify = true;
        },
        // 审批结果查看
        see(id) {
            this.$WFShowSceneLayer(id, 'view');
        },
        // 超任务发货管控处置
        preservation() {
            if (this.isModify === true) {
                this.loading = true;
                this.$axios
                    .post('/interfaceApi/sale/control/exceed_task/dispose', {
                        task_number: this.controlData.task_number,
                        disposal_description: this.controlData.disposal_description,
                        remarks: this.controlData.remarks,
                    })
                    .then(() => {
                        this.loading = false;
                        this.$message({
                            message: '处置完成！',
                            type: 'success',
                        });
                        this.$parent.hide();
                        this.$parent.hackReset();
                    })
                    .catch(error => {
                        this.loading = false;
                        this.$message.error(error.ErrorCode.Message);
                    });
            } else {
                this.$message({
                    message: '请先修改处置意见和和备注！',
                    type: 'warning',
                });
            }
        },
    },
};
</script>
<style lang="stylus" scoped>
.control
    display inline-block
    width auto
    padding 0.1rem
    background rgba(255, 255, 255, 0.5)
    .controlContant
        width 100%
        height 100%
        background #fff
        overflow hidden
        table
            width 8.6rem
            margin 0.2rem
            border-collapse collapse
            thead
                th
                    text-align center
            th, td
                height 0.6rem
                border 1px solid #D7D7D7
                font-size 0.18rem
                color #333
                font-weight 400
                text-indent 0.16rem
            th
                background #F6F8FC
                width 1.8rem
                text-align left
                color #022782
            td
                width 2.5rem
                .opinion
                    padding 0 0 0.1rem 0
                    text-indent 0
                    p
                        font-size 0.18rem
                        line-height 0.3rem
                        color #333333
                        text-indent 1
                    .info
                        text-align right
                        span
                            font-size 0.16rem
                            line-height 0.3rem
                            padding 0 0.1rem
                            margin 0.1rem 0.15rem 0 0.15rem
                            display inline-block
                            text-align left
                            text-indent 0
                        .name
                            width auto
                            height 0.3rem
                            background rgba(2, 133, 254, 0.06)
                            color #0285FE
                            border-radius 3px
                        .time
                            width auto
                            height 0.3rem
                            color #A77B54
                            border 1px solid rgba(230, 230, 230, 1)
                &.remarks
                    text-indent 0
                    textarea
                        width 100%
                        resize none
                        font-size 0.16rem
                        line-height 0.3rem
                        padding 0.05rem 0.1rem
                        font-weight 400
                        color #333
                        border none
                        font-family "微软雅黑"
                &.link
                    color #0285FE
                    text-decoration underline
                    cursor pointer
        footer
            text-align center
            width 100%
            margin-bottom 0.2rem
            button
                border 2px solid #1577D2
                background #fff
                width 1.84rem
                height 0.44rem
                font-size 0.2rem
                color #1577D2
                cursor pointer
                margin 0 0.2rem
                &:nth-of-type(2)
                    background #1577D2
                    color #fff
</style>